import React from 'react'
import PropTypes from 'prop-types'

import loadable from '@loadable/component'

const CardLink = loadable(() => import('./Card/Link'))

const pageBuilderCardLinks = ({ block }) => {
  const { cardLinks } = block

  return (
    <section className="container md:px-4 grid grid-cols-12 my-16 md:my-20">
      <div className="col-span-12 xl:col-span-10 xl:col-start-2 grid grid-cols-8 gap-y-8 md:gap-8 xl:gap-12">
        {cardLinks.map(content => (
          <CardLink
            key={content._key}
            content={content}
            className="col-span-12 md:col-span-4"
          />
        ))}
      </div>
    </section>
  )
}

pageBuilderCardLinks.propTypes = {
  block: PropTypes.object,
  // raw: PropTypes.object,
  index: PropTypes.number,
}

pageBuilderCardLinks.defaultProps = {
  block: {},
  // raw: {},
  index: 0,
}

export default pageBuilderCardLinks
